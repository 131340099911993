import React from 'react';
import {
  Filter,
  TextInput,
  List,
  Datagrid,
  TextField,
  NumberField,
  ReferenceInput,
  SelectInput,
  DateInput
} from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { permission, act } from '../permission';
import Aside from './aside';

const ItemsFilter = props => (
  <Filter {...props}>
    <DateInput label="период с:" source="date_from" alwaysOn />
    <DateInput label="по:" source="date_till" alwaysOn />

    {permission('schools', act.read) && (
      <ReferenceInput
        label="школа"
        source="school_id"
        reference="v1/schools"
        sort={{ field: 'title', order: 'ASC' }}
        alwaysOn
      >
        <SelectInput optionText="title" optionValue="id" />
      </ReferenceInput>
    )}
  </Filter>
);

export const ItemsList = ({ permissions, ...props }) => (
  <List
    title="отчёт по бюджету"
    filters={<ItemsFilter />}
    sort={{ field: 'title', order: 'ASC' }}
    bulkActionButtons={false}
    aside={<Aside />}
    {...props}
  >
    <Datagrid>
      <TextField source="title" label="класс" />
      <NumberField source="students_count" label="учеников" />
      <NumberField source="students_mo_count" label="малообеспеченных" />
      <NumberField source="service_count" label="служебных" />
    </Datagrid>
  </List>
);

export default ItemsList;
